.about__wrapper {
    text-align: center;
    color: var(--primary-color);
    padding: 42px 0;

    h1 {
        font-size: 36px;
        font-weight: 700;
        margin: 4px 0;
    }

    h3 {
        margin: 10px 0;
        font-size: 22px;
    }

    span {
        color: var(--secondary-color);
    }

    .about__resume__btn {
        padding: 6px 10px;
        font-size: 20px;
        background: var(--secondary-color);
        color: var(--black-color);
        font-weight: 600;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        margin: 20px 0;

        &:hover {
            opacity: 0.8;
        }
    }

}