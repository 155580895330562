.button__btn {
    padding: 6px 10px;
    font-size: 20px;
    background: var(--secondary-color);
    color: var(--black-color);
    font-weight: 600;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 32px 0;

    &:hover {
        opacity: 0.8;
    }

}