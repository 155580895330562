@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap');
@import 'bootstrap/dist/css/bootstrap.css'; 

:root {
  --primary-color: #ededed;
  --secondary-color: #bad2ff;
  --black-color: #000;
  --white-color: #fff;
  --background-primary-color: #3b3a3a;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;

  background-color: #252525;
}

p {
  margin: 0;
}