.project__item__wrapper {
    transition: transform 0.3s ease;
    margin: 18px 0;

    .project__item__img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 210px;
    }

    .project__item__desc {
        padding: 16px;
        background-color: var(--background-primary-color);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .project__item__name {
            color: var(--primary-color);
            font-size: 22px;
            font-weight: 600;
        }

        .project__item__technologies {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .project__item__detail {
            color: var(--primary-color);
            margin-top: 8px;
            display: -webkit-box; 
            -webkit-box-orient: vertical; 
            overflow: hidden; 
            -webkit-line-clamp: 5 ; 
            text-overflow: ellipsis; 
        }
    }

    &:hover {
        transform: scale(1.04);
    }
}

@media only screen and (min-width: 1300px) {
    .project__item__wrapper {
        height: 492px;

        .project__item__desc {
            height: 324px;
        }
    }
}