.notfound__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100vh;
    margin: 0 auto;
    text-align: center;

    .notfound__content {
        margin-top: auto;
        .notfound__code {
            font-size: 100px;
            color: var(--secondary-color);
        }
    
        .notfound__title {
            margin: 40px 0;
            font-size: 32px;
            color: var(--primary-color);
        }
    
        .notfound__detail {
            color: var(--primary-color);
            margin-top: 30px;
        }
    
        .notfound__detail + .notfound__detail {
            margin-top: 8px;
        }
    
        .notfound__btn__wrapper {
            margin: 18px 0;
        }
    }


    .notfound_copyright {
        color: var(--primary-color);
        margin: auto 0 28px 0;;
    }
}

@media only screen and (max-width: 768px) {
    .notfound__wrapper {
        width: 80%;
    }
}
