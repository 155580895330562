.project__wrapper {
    .project__see__more {
        text-decoration: none;
        text-align: center;
        color: var(--primary-color);
        font-size: 20px;
        font-weight: 700;
        display: inline-block;
        width: 100%;
        margin: 66px 0 12px;

        &:hover {
            color: var(--secondary-color);
        }
    }
}