.navigation {
    height: 62px;
    font-size: 24px;
    font-weight: 500;
    position: fixed;
    width: 100%;
    z-index: 1;

    .navigation__list {
        background-color: var(--background-primary-color);
        border-radius: 24px;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        width: 80%;
        margin: 10px auto;
        padding: 0;
        transition: margin 0.3s ease-in-out, width 0.3s ease-in-out,
            border-radius 0.3s ease-in-out, transform 0.3s ease;

        .navigation__item--link {
            color: var(--primary-color);
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                color: var(--secondary-color);
            }

            .navigation__item {}
        }
    }

    .top__navigation {
        border-radius: 0;
        width: 100%;
        margin-top: 0;
        border-bottom: 1px solid #e875a7;
    }
}

@media only screen and (max-width: 992px) {
    .navigation {
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .navigation__list {
        padding: 0 10px !important;
        height: 86% !important;

        .navigation__item__exp {
            display: none;
        }

        .navigation__item {
            line-height: 36px !important;

            .navigation__item--link {
                font-size: 15px;
                font-weight: 600 !important;
            }
        }
    }

    .top__navigation {
        padding: 0 10px !important;
    }
}

