.introduction__wrapper {
    text-align: center;
    color: var(--primary-color);
    padding: 130px 0 42px;

    h1 {
        font-size: 36px;
        font-weight: 700;
        margin: 4px 0;
    }

    h3 {
        margin: 10px 0;
        font-size: 22px;
    }

    span {
        color: var(--secondary-color);
    }
}